exports.components = {
  "component---src-pages-404-static-tsx": () => import("./../../../src/pages/404-static.tsx" /* webpackChunkName: "component---src-pages-404-static-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-article-page-static-tsx": () => import("./../../../src/pages/article-page-static.tsx" /* webpackChunkName: "component---src-pages-article-page-static-tsx" */),
  "component---src-pages-buildstatus-tsx": () => import("./../../../src/pages/buildstatus.tsx" /* webpackChunkName: "component---src-pages-buildstatus-tsx" */),
  "component---src-pages-filtered-listing-profile-static-tsx": () => import("./../../../src/pages/filtered-listing-profile-static.tsx" /* webpackChunkName: "component---src-pages-filtered-listing-profile-static-tsx" */),
  "component---src-pages-filtered-listing-static-tsx": () => import("./../../../src/pages/filtered-listing-static.tsx" /* webpackChunkName: "component---src-pages-filtered-listing-static-tsx" */),
  "component---src-pages-fund-static-tsx": () => import("./../../../src/pages/fund-static.tsx" /* webpackChunkName: "component---src-pages-fund-static-tsx" */),
  "component---src-pages-general-content-left-aligned-static-tsx": () => import("./../../../src/pages/general-content-left-aligned-static.tsx" /* webpackChunkName: "component---src-pages-general-content-left-aligned-static-tsx" */),
  "component---src-pages-general-content-static-tsx": () => import("./../../../src/pages/general-content-static.tsx" /* webpackChunkName: "component---src-pages-general-content-static-tsx" */),
  "component---src-pages-general-content-with-image-static-tsx": () => import("./../../../src/pages/general-content-with-image-static.tsx" /* webpackChunkName: "component---src-pages-general-content-with-image-static-tsx" */),
  "component---src-pages-homepage-static-tsx": () => import("./../../../src/pages/homepage-static.tsx" /* webpackChunkName: "component---src-pages-homepage-static-tsx" */),
  "component---src-pages-invest-now-static-tsx": () => import("./../../../src/pages/invest-now-static.tsx" /* webpackChunkName: "component---src-pages-invest-now-static-tsx" */),
  "component---src-pages-launchpad-jsx": () => import("./../../../src/pages/launchpad.jsx" /* webpackChunkName: "component---src-pages-launchpad-jsx" */),
  "component---src-pages-search-static-tsx": () => import("./../../../src/pages/search-static.tsx" /* webpackChunkName: "component---src-pages-search-static-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-strategy-static-tsx": () => import("./../../../src/pages/strategy-static.tsx" /* webpackChunkName: "component---src-pages-strategy-static-tsx" */),
  "component---src-pages-styleguide-components-accordion-tsx": () => import("./../../../src/pages/styleguide/components/accordion.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-accordion-tsx" */),
  "component---src-pages-styleguide-components-alert-banner-tsx": () => import("./../../../src/pages/styleguide/components/alert-banner.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-alert-banner-tsx" */),
  "component---src-pages-styleguide-components-author-simple-tsx": () => import("./../../../src/pages/styleguide/components/author-simple.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-author-simple-tsx" */),
  "component---src-pages-styleguide-components-author-with-description-tsx": () => import("./../../../src/pages/styleguide/components/author-with-description.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-author-with-description-tsx" */),
  "component---src-pages-styleguide-components-author-with-more-articles-tsx": () => import("./../../../src/pages/styleguide/components/author-with-more-articles.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-author-with-more-articles-tsx" */),
  "component---src-pages-styleguide-components-back-to-top-tsx": () => import("./../../../src/pages/styleguide/components/back-to-top.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-back-to-top-tsx" */),
  "component---src-pages-styleguide-components-blockquote-tsx": () => import("./../../../src/pages/styleguide/components/blockquote.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-blockquote-tsx" */),
  "component---src-pages-styleguide-components-button-rich-text-tsx": () => import("./../../../src/pages/styleguide/components/button-rich-text.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-button-rich-text-tsx" */),
  "component---src-pages-styleguide-components-columns-separator-tsx": () => import("./../../../src/pages/styleguide/components/columns-separator.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-columns-separator-tsx" */),
  "component---src-pages-styleguide-components-contact-us-block-tsx": () => import("./../../../src/pages/styleguide/components/contact-us-block.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-contact-us-block-tsx" */),
  "component---src-pages-styleguide-components-content-icon-tile-tsx": () => import("./../../../src/pages/styleguide/components/content-icon-tile.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-content-icon-tile-tsx" */),
  "component---src-pages-styleguide-components-cta-banner-tsx": () => import("./../../../src/pages/styleguide/components/cta-banner.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-cta-banner-tsx" */),
  "component---src-pages-styleguide-components-cta-tile-tsx": () => import("./../../../src/pages/styleguide/components/cta-tile.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-cta-tile-tsx" */),
  "component---src-pages-styleguide-components-disclaimer-tsx": () => import("./../../../src/pages/styleguide/components/disclaimer.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-disclaimer-tsx" */),
  "component---src-pages-styleguide-components-distribution-tsx": () => import("./../../../src/pages/styleguide/components/distribution.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-distribution-tsx" */),
  "component---src-pages-styleguide-components-download-item-tsx": () => import("./../../../src/pages/styleguide/components/download-item.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-download-item-tsx" */),
  "component---src-pages-styleguide-components-footer-tsx": () => import("./../../../src/pages/styleguide/components/footer.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-footer-tsx" */),
  "component---src-pages-styleguide-components-fund-facts-tsx": () => import("./../../../src/pages/styleguide/components/fund-facts.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-fund-facts-tsx" */),
  "component---src-pages-styleguide-components-hero-banner-tsx": () => import("./../../../src/pages/styleguide/components/hero-banner.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-hero-banner-tsx" */),
  "component---src-pages-styleguide-components-hero-carousel-tsx": () => import("./../../../src/pages/styleguide/components/hero-carousel.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-hero-carousel-tsx" */),
  "component---src-pages-styleguide-components-hubspot-form-embed-jsx": () => import("./../../../src/pages/styleguide/components/hubspot-form-embed.jsx" /* webpackChunkName: "component---src-pages-styleguide-components-hubspot-form-embed-jsx" */),
  "component---src-pages-styleguide-components-investment-team-tsx": () => import("./../../../src/pages/styleguide/components/investment-team.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-investment-team-tsx" */),
  "component---src-pages-styleguide-components-nav-price-tsx": () => import("./../../../src/pages/styleguide/components/nav-price.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-nav-price-tsx" */),
  "component---src-pages-styleguide-components-navigation-tsx": () => import("./../../../src/pages/styleguide/components/navigation.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-navigation-tsx" */),
  "component---src-pages-styleguide-components-page-header-author-subscribe-tsx": () => import("./../../../src/pages/styleguide/components/page-header-author-subscribe.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-page-header-author-subscribe-tsx" */),
  "component---src-pages-styleguide-components-pagination-tsx": () => import("./../../../src/pages/styleguide/components/pagination.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-pagination-tsx" */),
  "component---src-pages-styleguide-components-performance-charts-tsx": () => import("./../../../src/pages/styleguide/components/performance-charts.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-performance-charts-tsx" */),
  "component---src-pages-styleguide-components-performance-table-tsx": () => import("./../../../src/pages/styleguide/components/performance-table.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-performance-table-tsx" */),
  "component---src-pages-styleguide-components-profile-modal-tsx": () => import("./../../../src/pages/styleguide/components/profile-modal.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-profile-modal-tsx" */),
  "component---src-pages-styleguide-components-profile-tile-tsx": () => import("./../../../src/pages/styleguide/components/profile-tile.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-profile-tile-tsx" */),
  "component---src-pages-styleguide-components-related-articles-tsx": () => import("./../../../src/pages/styleguide/components/related-articles.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-related-articles-tsx" */),
  "component---src-pages-styleguide-components-social-share-full-width-tsx": () => import("./../../../src/pages/styleguide/components/social-share-full-width.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-social-share-full-width-tsx" */),
  "component---src-pages-styleguide-components-social-share-tsx": () => import("./../../../src/pages/styleguide/components/social-share.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-social-share-tsx" */),
  "component---src-pages-styleguide-components-strategy-cta-tsx": () => import("./../../../src/pages/styleguide/components/strategy-cta.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-strategy-cta-tsx" */),
  "component---src-pages-styleguide-components-strategy-page-layout-tsx": () => import("./../../../src/pages/styleguide/components/strategy-page-layout.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-strategy-page-layout-tsx" */),
  "component---src-pages-styleguide-components-tile-article-tsx": () => import("./../../../src/pages/styleguide/components/tile-article.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-tile-article-tsx" */),
  "component---src-pages-styleguide-components-tile-container-tsx": () => import("./../../../src/pages/styleguide/components/tile-container.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-tile-container-tsx" */),
  "component---src-pages-styleguide-components-two-col-page-section-tsx": () => import("./../../../src/pages/styleguide/components/two-col-page-section.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-two-col-page-section-tsx" */),
  "component---src-pages-styleguide-components-unit-prices-tsx": () => import("./../../../src/pages/styleguide/components/unit-prices.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-unit-prices-tsx" */),
  "component---src-pages-styleguide-components-video-section-tsx": () => import("./../../../src/pages/styleguide/components/video-section.tsx" /* webpackChunkName: "component---src-pages-styleguide-components-video-section-tsx" */),
  "component---src-pages-styleguide-core-buttons-tsx": () => import("./../../../src/pages/styleguide/core/buttons.tsx" /* webpackChunkName: "component---src-pages-styleguide-core-buttons-tsx" */),
  "component---src-pages-styleguide-core-chips-tsx": () => import("./../../../src/pages/styleguide/core/chips.tsx" /* webpackChunkName: "component---src-pages-styleguide-core-chips-tsx" */),
  "component---src-pages-styleguide-core-colours-js": () => import("./../../../src/pages/styleguide/core/colours.js" /* webpackChunkName: "component---src-pages-styleguide-core-colours-js" */),
  "component---src-pages-styleguide-core-elevation-shadows-jsx": () => import("./../../../src/pages/styleguide/core/elevation-shadows.jsx" /* webpackChunkName: "component---src-pages-styleguide-core-elevation-shadows-jsx" */),
  "component---src-pages-styleguide-core-favicon-js": () => import("./../../../src/pages/styleguide/core/favicon.js" /* webpackChunkName: "component---src-pages-styleguide-core-favicon-js" */),
  "component---src-pages-styleguide-core-form-elements-tsx": () => import("./../../../src/pages/styleguide/core/form-elements.tsx" /* webpackChunkName: "component---src-pages-styleguide-core-form-elements-tsx" */),
  "component---src-pages-styleguide-core-icons-jsx": () => import("./../../../src/pages/styleguide/core/icons.jsx" /* webpackChunkName: "component---src-pages-styleguide-core-icons-jsx" */),
  "component---src-pages-styleguide-core-img-tsx": () => import("./../../../src/pages/styleguide/core/img.tsx" /* webpackChunkName: "component---src-pages-styleguide-core-img-tsx" */),
  "component---src-pages-styleguide-core-spacing-jsx": () => import("./../../../src/pages/styleguide/core/spacing.jsx" /* webpackChunkName: "component---src-pages-styleguide-core-spacing-jsx" */),
  "component---src-pages-styleguide-core-table-tsx": () => import("./../../../src/pages/styleguide/core/table.tsx" /* webpackChunkName: "component---src-pages-styleguide-core-table-tsx" */),
  "component---src-pages-styleguide-core-typography-body-tsx": () => import("./../../../src/pages/styleguide/core/typography-body.tsx" /* webpackChunkName: "component---src-pages-styleguide-core-typography-body-tsx" */),
  "component---src-pages-styleguide-core-typography-display-tsx": () => import("./../../../src/pages/styleguide/core/typography-display.tsx" /* webpackChunkName: "component---src-pages-styleguide-core-typography-display-tsx" */),
  "component---src-pages-styleguide-core-video-tsx": () => import("./../../../src/pages/styleguide/core/video.tsx" /* webpackChunkName: "component---src-pages-styleguide-core-video-tsx" */),
  "component---src-pages-ucits-static-tsx": () => import("./../../../src/pages/ucits-static.tsx" /* webpackChunkName: "component---src-pages-ucits-static-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-fund-tsx": () => import("./../../../src/templates/fund.tsx" /* webpackChunkName: "component---src-templates-fund-tsx" */),
  "component---src-templates-general-content-landing-tsx": () => import("./../../../src/templates/general-content-landing.tsx" /* webpackChunkName: "component---src-templates-general-content-landing-tsx" */),
  "component---src-templates-general-content-tsx": () => import("./../../../src/templates/general-content.tsx" /* webpackChunkName: "component---src-templates-general-content-tsx" */),
  "component---src-templates-general-document-tsx": () => import("./../../../src/templates/general-document.tsx" /* webpackChunkName: "component---src-templates-general-document-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-invest-now-tsx": () => import("./../../../src/templates/invest-now.tsx" /* webpackChunkName: "component---src-templates-invest-now-tsx" */),
  "component---src-templates-profile-tsx": () => import("./../../../src/templates/profile.tsx" /* webpackChunkName: "component---src-templates-profile-tsx" */),
  "component---src-templates-strategy-tsx": () => import("./../../../src/templates/strategy.tsx" /* webpackChunkName: "component---src-templates-strategy-tsx" */),
  "component---src-templates-ucits-fund-tsx": () => import("./../../../src/templates/ucits-fund.tsx" /* webpackChunkName: "component---src-templates-ucits-fund-tsx" */)
}

